let headerSearchInput, headerSearchTrigger;
// Active class
const activeSearchClass = 'search-active';
// Header
const header = document.querySelector('header');

function triggerCallback(event) {
  if (!header.classList.contains(activeSearchClass)) {
    return expandSearch();
  } else if (header.classList.contains(activeSearchClass)) {
    return hideSearch();
  }
}

function expandSearch() {
  header.classList.add(activeSearchClass);
  setTimeout(setInputFocus, 600);
}

function setInputFocus() {
  headerSearchInput.focus();
}

function hideSearch() {
  header.classList.remove(activeSearchClass);
  headerSearchInput.blur();
}

function init() {
  // We make sure header tag that wraps the whole header && has data attrib. [data-header-search].
  if (header.hasAttribute('data-header-search') !== false) {
    // Search input
    headerSearchInput = document.querySelector('[data-hs-input]');
    // Search trigger
    headerSearchTrigger = document.querySelectorAll('[data-hs-trigger]');

    headerSearchTrigger.forEach((trigger) => {
      trigger.addEventListener('click', triggerCallback);
    });
  }
}

export default init;
