import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

// Set background images lazily
document.addEventListener('lazybeforeunveil', function (e) {
  const bg = e?.target?.getAttribute('data-bg') ?? null;
  if (bg && e.target) {
    e.target.style.backgroundImage = 'url(' + bg + ')';
  }
});
