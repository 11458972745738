import Headroom from 'headroom.js'

/**
 * Element to initiate headroom on
 * @param header
 * @return Headroom
 */

export default (header) => {
    const headroom  = new Headroom(header);
    // headroom.offset = 0; // For some reason, this was causing issues with the header
    headroom.tolerance.up = 5;
    headroom.init();
    return headroom;
}
