import Alpine from 'alpinejs';

import '@/lib/lazysizes';
import '../css/allura/main.css';
import { globalJS } from './allura/components';

globalJS();


// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AsyncAlpine from 'async-alpine';
AsyncAlpine.init(Alpine)
  .data('dealerLocator', () => import('./allura/modules/dealer-locator.js'))
  .data('cookieBanner', () => import('./allura/modules/cookie-banner.js'))
  .data('truckCalculator', () => import('./allura/modules/truck-calculator.js'))
  .start()
Alpine.start()

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR');
  });
}
